@font-face {
  font-family: 'KG Ten Thousand Reasons';
  src: url('../fonts/KGTenThousandReasons.eot');
  src: url('../fonts/KGTenThousandReasons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/KGTenThousandReasons.woff2') format('woff2'),
    url('../fonts/KGTenThousandReasons.woff') format('woff'),
    url('../fonts/KGTenThousandReasons.ttf') format('truetype'),
    url('../fonts/KGTenThousandReasons.svg#KGTenThousandReasons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'Kofik';
    src: url('../fonts/Kofik/justmeagaindownhere-webfont.eot');
    src: url('../fonts/Kofik/justmeagaindownhere-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Kofik/justmeagaindownhere-webfont.woff2') format('woff2'),
         url('../fonts/Kofik/justmeagaindownhere-webfont.woff') format('woff'),
         url('../fonts/Kofik/justmeagaindownhere-webfont.ttf') format('truetype'),
         url('../fonts/Kofik/justmeagaindownhere-webfont.svg#just_me_again_down_hereRg') format('svg');
    font-weight: normal;
    font-style: normal;
}
// @font-face {
//     font-family: 'Oswald';
//     src: url('../fonts/oswald-light-webfont.eot');
//     src: url('../fonts/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/oswald-light-webfont.woff2') format('woff2'),
//          url('../fonts/oswald-light-webfont.woff') format('woff'),
//          url('../fonts/oswald-light-webfont.ttf') format('truetype'),
//          url('../fonts/oswald-light-webfont.svg#oswaldlight') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
.animate, .animate:hover {
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
/* Header START */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(242, 172, 77, 0.8);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(242, 172, 77, 0.8);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(242, 172, 77, 0.8);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(242, 172, 77, 0.8);
}

header {
  width: 100%;
  height: 102px;
  position: fixed;
  background: url('../img/bgHeader.png') no-repeat center top;
  z-index: 9999;
  top: 0;
}
header .sc-logo {
  position: absolute;
  top: 0;
  left: 20px;
}
header .sc-logo img {
  width: 110px;
}
header #menu {
  display: none;
}
header nav {
  text-align: center;
}
header nav ul {
  display: inline-block;
  padding-left: 0;
}
header nav ul li {
  float: left;
  display: inline-block;
  margin: 0;
  background: url('../img/nav-separator.jpg') no-repeat left;
}
header nav ul li:nth-child(1) {
  background: none;
}
header nav ul li a {
  padding: 0 25px;
  height: 75px;
  display: block;
  text-transform: uppercase;
  font-size: 16px;
  color: #FFF;
  text-decoration: none;
  line-height: 85px;
  font-family: 'Oswald';
  font-weight: 200;
}

header nav ul li a:visited, header nav ul li a:active {
  color: #FFF !important;
  text-decoration: none;
}
header nav ul li a:hover {
  background: url('../img/nav-hover.png') no-repeat center top;
  color: #fbd294 !important;
  text-decoration: underline;
}
header nav ul li a.promo-nav {
  background: url('../img/bg-nav-eat.png') no-repeat center -2px;
  font-weight: 700;
  color: #915713 !important;
}
header nav ul li a.promo-christmas {
  height: 78px;
  background: url('../img/nav-christmas.png') no-repeat center -10px;
  min-width: 181px;
  font-weight: 700;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
}
header nav ul li a.promo-christmas:hover {
  color: #FFF !important;
}
header nav ul li a.active-nav {
  color: #fbd294 !important;
}
header nav ul li a.active-nav.promo-nav {
  color: #f2d9da !important;
}
header nav .social-nav {
  width: 120px;
  height: 75px;
  margin: 18px 0 0 10px;
  position: absolute;
  right: 20px;
  top: 0;
}
header nav .social-nav a {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background: #7d222b;
  text-align: center;
  line-height: 30px;
  position: absolute;
}
header nav .social-nav a:hover {
  background: #913740;
}
header nav .social-nav a img {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
header nav .social-nav .pin {
  margin: 23px 0 0 23px;
}
header nav .social-nav .inst {
  margin: 0 0 0 46px;
}
header nav .social-nav .gplus {
  margin: 23px 0 0 69px;
}
#menu {
  display: none
}

.music {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 120px;
  right: 20px;
  z-index: 9;
  opacity: 0.6;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.music:hover {
  opacity: 1
}
.music.play {
  background: url('../img/btn-music-unmute.png') no-repeat top;
}
.music.mute {
  background: url('../img/btn-music-mute.png') no-repeat bottom;
}

/* Header END */

/*------------------ PIERNIK STYLE ----------*/
.modal-preloader{
  display: none;
}
.main {
  font-family: 'Dosis', sans-serif;
  margin-top: 80px;
  @media screen and (max-width: 1200px) {
    margin-top: 60px;
  }
}
.top-margin{
  margin-top: 30px !important;
}
.clear-float{
  clear: both;
}
.no-padding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.renideer-container{
  position: relative;
  top: -60px;
  .reinder-animate {
    transform: rotate(-1deg);
    animation-name: rotateCG2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 0% 70%;
    animation-timing-function: ease-in-out;
  }
  .reindeer {
    position: relative;
    left: -30px;
    width: 300px;
    @media screen and (max-width: 1600px) {
      width: 250px;
    }
    @media screen and (max-width: 1250px) {
      width: 200px;
    }
  }
  .bauble {
    position: absolute;
    top: 130px;
    left: 175px;
    transform: rotate(-5deg);
    animation-name: rotateCG;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 50% 0%;
    animation-timing-function: ease-in-out;
    @media screen and (max-width: 1600px) {
      top: 110px;
      left: 145px;
      width: 80px;
    }
    @media screen and (max-width: 1250px) {
      top: 87px;
      left: 108px;
      width: 65px;
    }
  }
  .eyes {
    position: absolute;
    width: 95px;
    top: 250px;
    left: 70px;
    z-index: 1;
    animation: blink 3s infinite;
    opacity: 0;
    @media screen and (max-width: 1600px) {
      width: 80px;
      top: 208px;
      left: 53px;
    }
    @media screen and (max-width: 1250px) {
      width: 65px;
      top: 167px;
      left: 36px;
    }
  }
  .ear {
    position: absolute;
    top: 250px;
    left: 5px;
    z-index: 1;
    transform: rotate(-5deg);
    animation-name: rotateCG;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 100% 0%;
    @media screen and (max-width: 1600px) {
      top: 210px;
      left: -12px;
    }
    @media screen and (max-width: 1250px) {
      top: 170px;
      left: -32px;
    }
  }
  .validation-cloud {
    position: absolute;
    background: url('../img/cloud.png') no-repeat;
    background-size: 100%;
    width: 346px;
    height: 330px;
    padding: 100px 70px 50px;
    border-radius: 40%;
    bottom: -140px;
    left: -10px;
    opacity: 0;
    @media screen and (max-width: 1600px) {
      width: 280px;
      height: 267px;
      padding: 85px 45px 50px;
      bottom: -100px;
    }
    @media screen and (max-width: 1250px) {
      width: 240px;
      height: 229px;
      padding: 85px 35px 50px;
      bottom: -100px;
    }
    .resize {
      width: 100%;
      height: 100%;
      font-size: 48px;
      color: #a72224;
      font-style: italic;
    }
    .validation-info{
      display: table-cell;
      height: 170px;
      vertical-align: middle;
    }
    p {
      white-space: nowrap;
      animation: typing 3.5s steps(40, end);
    }
  }
}

.field-error{
  ul{
    list-style: none;
    color: #74181a;
    font-weight: 900;
    padding-left: 5px;
  }
}

.limit-info{
  color: #74181a;
  font-weight: 900;
  padding-left: 5px;
}

.limit-info:empty{
  display: none;
}

@keyframes rotateCG {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}
@keyframes rotateCG2 {
  0% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shake {
  // 10%, 90% {
  //   transform: translate3d(-1px, 0, 0);
  // }

  // 20%, 80% {
  //   transform: translate3d(2px, 0, 0);
  // }

  // 30%, 50%, 70% {
  //   transform: translate3d(-4px, 0, 0);
  // }

  // 40%, 60% {
  //   transform: translate3d(4px, 0, 0);
  // }
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes blink {
  96% {
    opacity: 0;
  }
  97% {
    opacity: 0.9;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.title-container{
  text-align: center;
  h1 {
    font-size: 0;
    color: transparent;
    position: absolute;
  }
  img{
    max-width: 100%;
    @media screen and (max-width: 1400px) {
      max-width: 80%;
    }
    @media screen and (max-width: 991px) {
      max-width: 95%;
    }
  }
  .title-icon{
    margin-top: -50px;
    margin: -50px 10px 0px 10px;
  }
  .star-icon, .tree-icon{
    margin-top: -70px;
  }
}
.center-section{
  h2{
    font-size: 28px;
    color: #fff;
    font-weight: 500;
    text-shadow: 0px 4px 0.5px rgba(105, 20, 22, 1);
  }
  .short-separator{
    display: block;
    width: 30px;
    height: 5px;
    background: #691416;
    margin: 30px auto;
    border: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .choose-container{
    margin-bottom: 30px;
    cursor: pointer;
    &:hover img {
      animation: shake 0.35s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
    .choose-wrapper{
      border-bottom: 4px solid #fff;
      border-radius: 20px;
      text-align: center;
      position: relative;
      cursor: pointer;
      img{
        max-width: 100%;
      }
      .template-markup{
        width: 36px;
        height: 36px;
        position: absolute;
        bottom: -20px;
        left: 0px;
        margin-left: calc(50% - 15px);
        background-image: url('../img/template-markup.png');
        background-position: top;
      }
      .active-markup{
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(242, 172, 77, 0.6);
        z-index: -9;
        border-radius: 18px;
        transform: scaleY(0);
        transform-origin: bottom;
      }
    }
    .choose-wrapper.active{
      .template-markup{
        background-position: bottom;
      }
      .active-markup{
        transform: scaleY(1);
        -webkit-backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
      }
    }
    .piernik-option{
      max-width: 100%;
    }
  }
  .preview-container{
    .coffe-preview-warpper{
      position: relative;
      img{
        max-width: 100%;
      }
      .preview-txt{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        background: none;
        text-align: center;
        font-family: Kofik;
        font-size: 4vw;
        color: #f3ebc5;
        line-height: 80px;
        resize: none;
        outline: none;
        @media screen and (max-width: 1200px) {
          font-size: 7vw;
        }
        @media screen and (max-width: 991px) {
          font-size: 7vw;
        }
        @media screen and (max-width: 768px) {
          font-size: 10vw;
        }
      }
      #firstLinePreview{
        margin-top: 35%;
        @media screen and (max-width: 1200px) {
          margin-top: 37%;
        }
        @media screen and (max-width: 991px) {
          margin-top: 22%;
        }
        @media screen and (max-width: 768px) {
          margin-top: 28%;
        }
      }
      #secondLinePreview{
        margin-top: 60%;
        @media screen and (max-width: 1200px) {
          margin-top: 64%;
        }
        @media screen and (max-width: 991px) {
          margin-top: 37%;
        }
        @media screen and (max-width: 768px) {
          margin-top: 47%;
        }
      }
    }
  }
  .form-container{
    text-align: left;
    label{
      font-size: 22px;
      font-weight: 600;
      color: #fff;
    }
    .input-style-1{
      width: 100%;
      background: none;
      border: none;
      border-radius: 20px;
      height: 50px;
      margin-bottom: 30px;
      padding: 0px 20px;
      padding-bottom: 9px;
      outline: none;
      font-size: 32px;
      color: #fff;
      -webkit-box-shadow: 0px 4px 0px 0px rgba(255,255,255,1);
      -moz-box-shadow: 0px 4px 0px 0px rgba(255,255,255,1);
      box-shadow: 0px 4px 0px 0px rgba(255,255,255,1);
      -webkit-appearance: none;
    }
    .examples-btn{
      display: table;
      margin-bottom: 30px;
      border-radius: 18px;
      cursor: pointer;
      transform: scale(1);
      padding: 15px 20px;
      div{
        display: table-cell;
        vertical-align: middle;
      }
      p{
        color: #f3ebc5;
        font-size: 28px;
        margin: 0px;
        line-height: 30px;
      }
      strong{
        font-size: 32px;
      }
    }
    .examples-btn:hover .examples-btn-images{
      transform: scale(1.2) rotate(3deg);
    }
    .examples-btn:hover{
      background: rgba(242, 172, 77, 0.6);
    }
  }
  .form-row{
    form{
      textarea{
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        background-color: #9d5014;
        border: 1px dashed #fff;
        resize: none;
        overflow: hidden;
        margin: 15px auto;
      }
    }
  }
  h3{
    font-size: 20px;
    line-height: 28px;
    color: #fff;
  }
  h4{
    color: #fff;
    font-weight: 400;
    font-size: 22px;
  }
  h4.recipient-title {
    font-size: 36px;
    font-weight: 200 !important;
    @media screen and (max-width: 1600px) {
      font-size: 30px;
    }
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
  }
}
.noselect {
   cursor: default;
   -webkit-user-select: none !important;
   -webkit-touch-callout: none !important;
   -khtml-user-select: none !important;
   -moz-user-select: none !important;
   -ms-user-select: none !important;
   -o-user-select: none !important;
   user-select: none !important;
}
.input-style{
  width: 100%;
  background-color: #9d5014;
  border-radius: 10px;
  border: 1px dashed #fff;
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.recipient-form{
  min-height: 575px;
  div[class*='col-']{
    text-align: left;
  }
  label, .disclaimer{
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    a {
      color: #FFF;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .user-col{
    border-right: 1px dashed #fff;
  }
  .recipient-col-style{
    border-left: 1px dashed #fff;
    @media screen and (max-width: 768px) {
      border-left: 0px;
    }
  }
  input[type="checkbox"]{
    z-index: 9;
    position: absolute;
    height: 25px;
    width: 25px;
    opacity: 0;
    cursor: pointer;
  }
  .checkbox-markup{
    display: inline-block;
    width: 17px;
    height: 17px;
    border: 1.5px solid #fff;
    border-radius: 50%;
    background-color: #9d5014;
    margin-bottom: -3px;
  }
  input[type="checkbox"]:checked + .checkbox-markup{
    background-color: #691416;
  }
}
.form-terms-row{
  table{
    td{
      padding: 0px 5px;
      vertical-align: top;
    }
  }
}
.recipient-btn{
  padding: 7px 20px 10px 20px;;
  border-radius: 20px;
  border: 3px solid #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  margin: 15px;
  cursor: pointer;
}
.recipient-btn img{
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}
.recipient-btn.active{
  background-color: #691416;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
  box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
}
.recipient-btn:hover{
  color: #fff;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
  box-shadow: 0px 0px 10px 1px rgba(255,255,255,1);
}
.recipient-btn:visited{
  color: #fff;
  text-decoration: none;
}
.input-font-style{
  padding: 20px;
  color: #fff;
  font-size: 14px;
}
.btn-holder{
  display: inline-block;
  // min-height: 60px;
  vertical-align: middle;
  margin: 30px 15px;
  @media screen and (max-width: 494px) {
    margin: 30px 15px 0px;
  }
}
.vertical-nav{
  height: 50vh;
  margin-top: -40px;
  .oval{
    width: 400px;
    height: 500px;
    background: rgba(92, 45, 25, 0.6);
    border-radius: 400px / 662px;
    position: absolute;
    bottom: -250px;
    right: -330px;
    table{
      height: 100%;
      margin-left: 40px;
      tr{
        border-left: 3px solid #fff;
        td{
          vertical-align: top;
          text-align: center;
          .view-nav-markup{
            display: inline-block;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: #81471d;
            margin-left: -9px;
          }
          .markup-info{
            position: absolute;
            left: 0px;
            margin-left: -100px;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            display: none;
          }
        }
      }
    }
    table tr:first-child, table tr:last-child{
      height: 30%;
      border-color: transparent;
    }
    table tr:nth-of-type(7){
      border-color: transparent;
    }
    table tr:nth-of-type(7) span{
      margin-bottom: 2px;
    }
    table tr:nth-of-type(2) span{
      margin-bottom: 2px;
    }
    .active .view-nav-markup{
      background: #fff !important;
      width: 15px;
      height: 15px;
    }
    .active .markup-info{
      display: block;
    }
  }
}
.row-eq-height{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row-eq-height>[class*='col-'] {
  display: flex;
  flex-direction: column;
}
.short-separator{
  display: block;
  width: 30px;
  height: 5px;
  background: #691416;
  margin: 30px auto;
  border: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.separator-white{
  background: #fff;
}
.btn-shape{
  display: inline-block;
  color: #fff;
  border: 0;
  // height: 45px;
  padding: 5px 18px;
  border-radius: 10px;
  line-height: 45px;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-size: 22px;
  font-weight: 500;
  outline: none;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 36px;
  }
}
.my-chevron{
  margin-left: 10px;
}
.btn-dark{
  background: #691416;
  -webkit-box-shadow: 0px 6px 0px 0px rgba(84,10,12,1);
  -moz-box-shadow: 0px 6px 0px 0px rgba(84,10,12,1);
  box-shadow: 0px 6px 0px 0px rgba(84,10,12,1);
  &:hover {
    background: #74181a;
  }
}
.btn-bright{
  background: #d18b3a;
  -webkit-box-shadow: 0px 6px 0px 0px rgba(143,61,0,1);
  -moz-box-shadow: 0px 6px 0px 0px rgba(143,61,0,1);
  box-shadow: 0px 6px 0px 0px rgba(143,61,0,1);
  &:hover {
    background: #e89a41;
  }
}
.btn-fb{
  background: #627aad;
  -webkit-box-shadow: 0px 6px 0px 0px rgba(59,89,152,1);
  -moz-box-shadow: 0px 6px 0px 0px rgba(59,89,152,1);
  box-shadow: 0px 6px 0px 0px rgba(59,89,152,1);
  &:hover {
    background: #6d88c0;
  }
  img{
    float: left;
    margin-top: 8px;
    margin-right: 10px;
  }
}
.btn-red{
  background: #c10c10;
  -webkit-box-shadow: 0px 6px 0px 0px rgba(158,10,13,1);
  -moz-box-shadow: 0px 6px 0px 0px rgba(158,10,13,1);
  box-shadow: 0px 6px 0px 0px rgba(158,10,13,1);
  height: auto !important;
  font-size: 24px;
  padding: 8px 24px;
  &:hover {
    background: #ce0d11;
  }
}
.btn-shape:active{
  box-shadow: none;
  margin-top: 6px;
  margin-bottom: 0px;
}
.btn-shape:hover, .btn-shape:visited, .btn-shape:link{
  text-decoration: none !important;
  color: #fff;
}
.regulations-container{
  margin-bottom: 30px;
  a, span{
    font-size: 14px;
    display: inline-block;
    color: #ffcc91;
  }
  span{
    margin: 0px 10px;
  }
}
.modal-bg{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(83, 20, 25, 0.9);
  z-index: 9999;
  display: none;
  overflow-y: scroll;
  h2{
    font-size: 36px;
    color: #fff;
  }
  img{
    max-width: 100%;
    @media screen and (max-width: 991px) {
      max-width: 60%;
    }
  }
  .example-container{
    padding: 30px;
  }
}
.modal-bg.active{
  display: block;
}
@media screen and (min-width: 991px) {
  .hidden-md-up{
    display: none !important;
  }
}
/*------------------- PIERNIK STYLE END ------*/

/* Media Queries START */

@media screen and (max-width: 1600px) {
  header nav ul li a {
    padding: 0 18px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1400px) {
  header nav ul li a {
    padding: 0 13px;
    font-size: 13px;
  }
  header nav ul li a.promo-christmas {
    background: url('../img/nav-christmas.png') no-repeat center 0px;
    background-size: 144px;
    min-width: 143px;
  }
}

@media screen and (max-width: 1200px) {

  header {
    height: 70px;
    background-position: center bottom;
  }
  header .sc-logo {
    position: static;
  }
  header .sc-logo img {
    width: 70px;
  }
  header nav ul li {
    background-position: left bottom;
  }
  header nav ul li a {
    padding: 0 11px;
    height: 50px;
    font-size: 13px;
    line-height: 60px;
  }
  header nav ul li a:hover {
    background-position: center bottom;
  }
  header nav ul li a.active-nav.promo-nav {
    background: url('../img/bg-nav-eat-active-sm.png') no-repeat center top;
  }
  header nav ul li a.promo-nav {
    background: url('../img/bg-nav-eat-sm.png') no-repeat center top;
    font-size: 13px;
  }
  header nav ul li a.promo-christmas {
    background: url('../img/nav-christmas-mobile.jpg') no-repeat center 0;
    height: auto;
    font-size: 13px;
  }
  header nav ul li a.promo-christmas:hover {
    color: #FFF !important;
    background: url('../img/nav-christmas-mobile.jpg') no-repeat center 0;
  }
  header nav .social-nav {
    width: 80px;
    height: 45px;
    margin: 10px 0 0 10px;
  }
  header nav .social-nav a {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }
  header nav .social-nav .pin {
    margin: 16px 0 0 16px;
  }
  header nav .social-nav .inst {
    margin: 0 0 0 32px;
  }
  header nav .social-nav .gplus {
    margin: 16px 0 0 48px;
  }




  header #menu {
    display: block;
    width: 45px;
    height: 45px;
    background: #401317;
    text-align: center;
    float: right;
    display: block;
    cursor: pointer;
    margin: 7px 0 0 0;
    color: #FFF;
    font-size: 48px;
    line-height: 32px;
  }
  header #menu img {
    width: 45px;
  }
  header #menu:hover {
    color: #fbd294;
  }
  header nav.js {
    display: none;
  }
  header .nav-opened {
    display: block !important;
  }
  header nav {
    width: 100%;
    margin-top: -6px;
    background: #401317;
  }
  header nav ul {
    padding: 0;
    width: 100%;
    height: auto;
    background: #401317;
  }
  header nav ul li {
    width: 100%;
    height: 40px;
    text-align: center;
    display: block;
    border-bottom: 1px solid #300d11;
    background: none;
  }
  header nav ul li a {
    line-height: 40px;
    margin: 0;
    padding: 0;
    height: 40px;
  }
  header nav ul li a:hover {
    background-image: none;
    background-color: #300d11;
    height: 40px;
  }
  header nav ul li a.active-nav.promo-nav {
    background: none #d04e6a;
  }
  header nav ul li a.promo-nav {
    background: none #ffcd53;
    font-size: 16px;
  }
  header nav ul li a.christmas-nav {
    background: none #f34439;
    height: 40px;
    font-weight: 700;
  }
  header nav .social-nav {
    width: 100%;
    height: 50px;
    margin: 0;
    text-align: center;
    position: static;
  }
  header nav .social-nav a {
    position: static;
    display: inline-block;
    margin: 0 20px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  header nav .social-nav .pin, header nav .social-nav .inst, header nav .social-nav .gplus {
    margin: 0 20px;
  }
}


/* Media Queries END */

html {
  font-size: 24px;
}
body {
  background-image: url(../img/background_brown.jpg);
  background-repeat: no-repeat;
	width: 100%;
	height: auto;
  background-size: cover;
  overflow-x: hidden;
}

body > img{
  display: none;
}
a{
  cursor: pointer;
  text-decoration: none;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
}
.row {
  margin: 0;
  padding: 0;
}
.inline {
  display: inline-block;
}

.field-error ul li {
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
  background: #980005;
  padding: 5px 10px;
  margin: -10px 0 15px 0px;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  &:after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(152, 0, 5, 0);
    border-bottom-color: #980005;
    border-width: 5px;
    margin-left: -5px;
  }
}


/*------------------ FOOTER ------------------*/
footer, footer a {
  width: 100%;
  font-family: 'Oswald';
  color: #fff;
  /* padding-left: 15px;
  padding-right: 15px; */
  height: 160px;
  background: url(../img/footer/bg-footer.png)  no-repeat center top;
  text-align: center;
  position: relative;
  font-weight: 200;
  bottom: 0;
}
footer a {
  background: none;
}
footer p {
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  font-weight: 200;
}
footer p.txt-footer {
  padding-top: 75px;
  margin: 0;
}
footer p.txt-footer a {
 color: white;
 text-decoration: none;
}
footer ul {
 margin-top: 45px;
 padding-left: 0px;
}
footer ul li {
 display: inline-block;
 margin: 0 5px;
 list-style-type: none;
}
footer ul li img {
 vertical-align: middle;
 border: 0;
}
footer ul li p {
 line-height: 14px;
}

footer .footer-wrapper{
  height: 50px;
  width: 100%;
  background-color:#751e26;
}
/*------------------ FOOTER end ------------------*/
